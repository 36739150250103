@import 'mixins';

@mixin bootstrap-decorations($prefix: null) {
  .border-#{$prefix}top {
    border-top: 1px solid #dee2e6 !important;
  }
}

@include bootstrap-decorations;

@include breakpoint-xs {
  @include bootstrap-decorations(xs-);
}

@include breakpoint-sm {
  @include bootstrap-decorations(sm-);
}

@include breakpoint-md {
  @include bootstrap-decorations(md-);
}

@include breakpoint-lg {
  @include bootstrap-decorations(lg-);
}

@include breakpoint-xlg {
  @include bootstrap-decorations(xlg-);
}
