@use 'sass:map';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/utilities';

// change the theme
$theme-colors: (
  'primary': #1a3a32,
  'primary01': rgb(26 58 50 / 0.01),
  'primary02': rgb(26 58 50 / 0.02),
  'primary04': rgb(26 58 50 / 0.04),
  'primary06': rgb(26 58 50 / 0.06),
  'primary12': rgb(26 58 50 / 0.12),
  'primary20': rgb(26 58 50 / 0.2),
  // Bezel Green
  'secondary': #9b9c9a,
  'success': #07745b,
  'success02': rgb(7 116 91 / 0.02),
  'success04': rgb(7 116 91 / 0.04),
  'success05': rgb(7 116 91 / 0.05),
  'success06': rgb(7 116 91 / 0.06),
  'success4': rgb(7 116 91 / 0.4),
  'warning': #e4a11b,
  'danger': #f15e5e,
  'danger04': rgb(241 94 94 / 0.04),
  'danger-pale': rgb(192 98 98),
  'light': rgb(255 252 249),
  'muted': #d2d6d1,
  'beige': #f3eeeb,
  'transparent': #ffffff40,
  'white': #fff,
  'dark': #030d0b,
  'lightSuccess': rgb(7 116 91 / 0.071),
  'lightSuccessHex': #eff2ed,
  'lightPrimary': rgb(26 58 50 / 0.02),
  'lightPrimaryText': rgb(26 58 50 / 0.6),
  'lightPrimaryBackground': rgb(26 58 50 / 0.02),
  'borderSuccess': rgb(7 116 91 / 0.2),
  'green': rgb(10 142 109),
  'green12': rgb(10 142 109 / 0.12),
  'green2': rgb(10 142 109 / 0.2),
  'lightPrimaryBackgroundHex': #faf8f4,
  'turquoise': #defff8,
  'turquoise90': rgb(222 255 248 / 0.9),
  'banner': #eee9e3,
  'pink': rgb(248 190 190 / 1),
  'pink6': rgb(248 190 190 / 0.06),
  'pink24': rgb(248 190 190 / 0.24),
  'pink90': rgb(248 190 190 / 0.9),
  'pink95': rgb(248 190 190 / 0.95),
  'maroon': rgb(147 21 21 / 1),
  'light2': rgb(255 252 249 / 0.02),
  'light5': rgb(255 252 249 / 0.05),
  'light6': rgb(255 252 249 / 0.06),
  'light8': rgb(255 252 249 / 0.08),
  'light10': rgb(255 252 249 / 0.1),
  'light20': rgb(255 252 249 / 0.2),
  'light24': rgb(255 252 249 / 0.24),
  'light40': rgb(255 252 249 / 0.4),
  'light60': rgb(255 252 249 / 0.6),
  'light80': rgb(255 252 249 / 0.8),
  'light90': rgb(255 252 249 / 0.9),
  'grey': rgb(212 212 212),
  'dark-grey': rgb(52 52 53),
  'pale': rgb(209 255 245),
  'pale6': rgb(209 255 245 / 0.06),
  'pale8': rgba(209 255 245 / 0.08),
  'pale10': rgba(209 255 245 / 0.1),
  'pale12': rgb(209 255 245 / 0.12),
  'pale16': rgb(209 255 245 / 0.16),
  'pale24': rgb(209 255 245 / 0.24),
  'lightButton': rgb(244 242 240),
  'lightGrey': rgb(232 233 229),
  'lightGreen': rgb(231 241 235),
  'requestGreen': rgb(7 113 86),
  'requestGreen6': rgb(7 113 86 / 0.06),
  'requestGreen10': rgb(7 113 86 / 0.1),
  'requestGreen16': rgb(7 113 86 / 0.16)
);
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 9,
  10: $spacer * 10.5,
  11: $spacer * 12
);
$utilities: map.merge(
  $utilities,
  (
    'border-color': map.merge(
        map.get($utilities, 'border-color'),
        (
          values: map.merge($theme-colors, ())
        )
      ),
    'rounded': map.merge(
        map.get($utilities, 'rounded'),
        (
          values: map.merge(
              map.get(map.get($utilities, 'rounded'), 'values'),
              (
                1: 10px,
                2: 20px,
                3: 30px
              )
            )
        )
      ),
    'width': map.merge(
        map.get($utilities, 'width'),
        (
          values: map.merge(
              map.get(map.get($utilities, 'width'), 'values'),
              (
                0: 0%,
                5: 5%,
                10: 10%,
                15: 15%,
                20: 20%,
                24: 24%,
                25: 25%,
                30: 30%,
                32: 32%,
                35: 35%,
                45: 45%,
                48: 48%,
                49: 49%,
                50: 50%,
                60: 60%,
                73: 73%,
                75: 75%,
                90: 90%,
                95: 95%,
                100: 100%
              )
            )
        )
      ),
    'height': map.merge(
        map.get($utilities, 'height'),
        (
          values: map.merge(
              map.get(map.get($utilities, 'height'), 'values'),
              (
                0: 0%,
                5: 5%,
                10: 10%,
                20: 20%,
                25: 25%,
                30: 30%,
                35: 35%,
                50: 50%,
                75: 75%,
                85: 85%,
                90: 90%,
                95: 95%,
                100: 100%
              )
            )
        )
      ),
    'min-viewport-height': map.merge(
        map.get($utilities, 'min-viewport-height'),
        (
          values: map.merge(
              map.get(map.get($utilities, 'min-viewport-height'), 'values'),
              (
                25: 25vh,
                50: 50vh,
                60: 60vh,
                75: 75vh,
                100: 100vh
              )
            )
        )
      ),
    'viewport-height': map.merge(
        map.get($utilities, 'viewport-height'),
        (
          values: map.merge(
              map.get(map.get($utilities, 'viewport-height'), 'values'),
              (
                15: 15vh,
                20: 20vh,
                25: 25vh,
                40: 40vh,
                50: 50vh,
                60: 60vh,
                75: 75vh,
                100: 100vh
              )
            )
        )
      ),
    'opacity': map.merge(
        map.get($utilities, 'opacity'),
        (
          values: map.merge(
              map.get(map.get($utilities, 'opacity'), 'values'),
              (
                0: 0,
                10: 0.1,
                20: 0.2,
                25: 0.25,
                30: 0.3,
                40: 0.4,
                50: 0.5,
                60: 0.6,
                70: 0.7,
                75: 0.75,
                80: 0.8,
                90: 0.9,
                100: 1
              )
            )
        )
      ),
    'color': map.merge(
        map.get($utilities, 'color'),
        (
          values: map.merge($theme-colors, ())
        )
      ),
    'background-color': map.merge(
        map.get($utilities, 'background-color'),
        (
          values: map.merge($theme-colors, ())
        )
      ),
    'margin': map.merge(
        map.get($utilities, 'margin'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'margin-x': map.merge(
        map.get($utilities, 'margin-x'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'margin-y': map.merge(
        map.get($utilities, 'margin-y'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'margin-top': map.merge(
        map.get($utilities, 'margin-top'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'margin-end': map.merge(
        map.get($utilities, 'margin-end'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'margin-bottom': map.merge(
        map.get($utilities, 'margin-bottom'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'margin-start': map.merge(
        map.get($utilities, 'margin-start'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'padding': map.merge(
        map.get($utilities, 'padding'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'padding-x': map.merge(
        map.get($utilities, 'padding-x'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'padding-y': map.merge(
        map.get($utilities, 'padding-y'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'padding-top': map.merge(
        map.get($utilities, 'padding-top'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'padding-end': map.merge(
        map.get($utilities, 'padding-end'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'padding-bottom': map.merge(
        map.get($utilities, 'padding-bottom'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      ),
    'padding-start': map.merge(
        map.get($utilities, 'padding-start'),
        (
          values: map.merge(
              $spacers,
              (
                auto: auto
              )
            )
        )
      )
  )
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';
