.input {
  background: #fffcf9;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    opacity: 0.4;
    font-size: 14px;
  }

  &.is-error {
    border-color: var(--color-danger) !important;
    color: var(--color-danger) !important;

    &::placeholder {
      color: var(--color-danger) !important;
    }
  }
}
