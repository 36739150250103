@import 'mixins';

@mixin bootstrap-margins($prefix: null) {
  .mr-#{$prefix}05 {
    margin-right: 0.5rem;
  }

  .mr-#{$prefix}1 {
    margin-right: 1rem;
  }

  .mr-#{$prefix}2 {
    margin-right: 2rem;
  }

  .mr-#{$prefix}3 {
    margin-right: 3rem;
  }
}

@mixin bootstrap-heights($prefix: null) {
  .h-#{$prefix}30 {
    height: 30%;
  }

  .h-#{$prefix}40 {
    height: 40%;
  }

  .h-#{$prefix}45 {
    height: 45%;
  }

  .h-#{$prefix}50 {
    height: 50%;
  }

  .h-#{$prefix}60 {
    height: 60%;
  }

  .h-#{$prefix}70 {
    height: 70%;
  }

  .h-#{$prefix}80 {
    height: 80%;
  }

  .h-#{$prefix}90 {
    height: 90%;
  }

  .h-#{$prefix}100 {
    height: 100%;
  }

  .height-#{$prefix}height-fit-content {
    height: fit-content;
  }
}

@mixin bootstrap-widths($prefix: null) {
  .w-#{$prefix}20 {
    width: 20% !important;
  }

  .w-#{$prefix}25 {
    width: 25% !important;
  }

  .w-#{$prefix}30 {
    width: 30% !important;
  }

  .w-#{$prefix}40 {
    width: 40% !important;
  }

  .w-#{$prefix}45 {
    width: 45% !important;
  }

  .w-#{$prefix}50 {
    width: 50% !important;
  }

  .w-#{$prefix}55 {
    width: 55% !important;
  }

  .w-#{$prefix}60 {
    width: 60% !important;
  }

  .w-#{$prefix}65 {
    width: 65% !important;
  }

  .w-#{$prefix}70 {
    width: 70% !important;
  }

  .w-#{$prefix}75 {
    width: 75% !important;
  }

  .w-#{$prefix}80 {
    width: 80% !important;
  }

  .w-#{$prefix}85 {
    width: 85% !important;
  }

  .w-#{$prefix}90 {
    width: 90% !important;
  }

  .w-#{$prefix}100 {
    width: 100% !important;
  }

  .w-#{$prefix}auto {
    width: auto !important;
  }
}

@include bootstrap-margins;
@include bootstrap-heights;
@include bootstrap-widths;

@include breakpoint-xs {
  @include bootstrap-margins(xs-);
  @include bootstrap-heights(xs-);
  @include bootstrap-widths(xs-);
}

@include breakpoint-sm {
  @include bootstrap-margins(sm-);
  @include bootstrap-heights(sm-);
  @include bootstrap-widths(sm-);
}

@include breakpoint-md {
  @include bootstrap-margins(md-);
  @include bootstrap-heights(md-);
  @include bootstrap-widths(md-);
}

@include breakpoint-lg {
  @include bootstrap-margins(lg-);
  @include bootstrap-heights(lg-);
  @include bootstrap-widths(lg-);
}

@include breakpoint-xlg {
  @include bootstrap-margins(xlg-);
  @include bootstrap-heights(xlg-);
  @include bootstrap-widths(xlg-);
}
