.slide-down {
  &-enter {
    transform: translateY(100%);
  }

  &-enter-active {
    transition: all 200ms ease-in-out;
    transform: translateY(0);
  }

  &-exit {
    transform: translateY(0);
  }

  &-exit-active {
    transform: translateY(100%);
    transition: all 200ms ease-in-out;
  }
}
