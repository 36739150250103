.selectionItem:hover {
  border-color: var(--color-gray40) !important;
}

.requestBoardButton:hover {
  border-color: var(--color-primary12) !important;

  &:hover {
    border-color: var(--color-gray40) !important;
  }
}

.clickableText {
  opacity: 0.6;
  transition: opacity 300ms;

  &:hover {
    opacity: 0.9;
  }
}

.tagBlurred {
  background: rgb(0 0 0 / 0.5);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
}
