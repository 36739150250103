.input {
  background: #FFFCF9;
  outline: none;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    opacity: 0.4;
  }

  &.is-error {
    border-color: rgb(var(--bs-danger-rgb)) !important;
    color: rgb(var(--bs-danger-rgb)) !important;

    &::placeholder {
      color: rgb(var(--bs-danger-rgb)) !important;
    }
  }
}