@media (width <= 767px) {
  .fade.modal {
    transform: translateY(100%);
  }

  .fade.modal.show {
    transform: translateY(0);
    transition: transform 400ms cubic-bezier(0, 0.98, 0.51, 0.99);
  }

  .modal-dialog {
    transition: none !important;
  }
}

/* increase hit area of slider items */
.rc-slider-handle-1::after,
.rc-slider-handle-2::after {
  position: absolute;
  content: '';
  top: -19px;
  height: 50px;
  width: 35px;
}

.rc-slider-handle-1::after {
  left: -23px;
}

.rc-slider-handle-2::after {
  left: -2px;
}
