@import 'styles/mixins';

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 37px 0 rgb(0 0 0 / 0.3);
  min-width: 48px;
  height: 48px;
  width: 48px;
  border-radius: 50%;

  &:hover {
    transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
    transform: scale(1.1);
  }
}

.backIcon {
  left: 20px;
}

.closeIcon {
  right: 20px;
}

.footer {
  margin-bottom: 3rem;

  @include breakpoint-md {
    margin-bottom: 5rem;
  }
}

.common {
  background-color: var(--color-light);
  width: 100%;
  border-left: 0;

  // 1 more then bootstrap modal z-index
  z-index: 1056;
}

.slideout {
  @include breakpoint-md {
    width: 412px;
    border-left: 1px solid rgb(0 0 0 / 0.2);
  }
}

.slideoutLarge {
  @include breakpoint-md {
    width: 800px;
    border-left: 1px solid rgb(0 0 0 / 0.2);
  }
}
