@mixin breakpoint-xs {
  @media only screen and (width >= 576px) {
    @content;
  }
}

@mixin breakpoint-sm {
  @media only screen and (width >= 768px) {
    @content;
  }
}

@mixin breakpoint-md {
  @media only screen and (width >= 992px) {
    @content;
  }
}

@mixin breakpoint-lg {
  @media only screen and (width >= 1200px) {
    @content;
  }
}

@mixin breakpoint-xlg {
  @media only screen and (width >= 1400px) {
    @content;
  }
}
