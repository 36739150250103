.carousel-indicators {
  background: #fff;
  width: max-content;
  margin: 0 auto;
  border-radius: 20px;
  padding: 0 10px;
  bottom: 50px;
  border: none;
  height: 18px;
  display: flex;
  align-items: center;

  button {
    width: 5px !important;
    height: 5px !important;
    border-radius: 50%;
    margin: 0 6px;
    background-color: #ccc;

    &.active {
      background-color: #000;
    }
  }
}
