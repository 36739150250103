@import 'styles/mixins';

.mainContent {
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

  @include breakpoint-sm {
    padding-top: 73px !important;
    padding-bottom: 198px !important;
  }
}

.stickyOffset {
  top: 150px;
  z-index: 0;
}

.sectionTitle {
  padding-left: 0;
}

.discountCode::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #1a2a32;
  opacity: 0.1;
}

.discountCode:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1a2a32;
  opacity: 0.1;
}

.discountCode::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1a2a32;
  opacity: 0.1;
}

.receipt {
  padding-left: 0;
  padding-right: 0;
}

@media (width <= 991px) {
  .mainContent {
    padding: 5rem 18px;
  }

  .stickyOffset {
    top: 5rem;
    z-index: 0;
  }

  .sectionTitle {
    padding-left: 6px;
  }

  .receipt {
    padding-left: 6px;
    padding-right: 6px;
  }
}
