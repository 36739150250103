@import 'styles/mixins';

$left-padding: 5rem;
$mobile-left-padding: 2rem;
$right-padding: 5rem;

.left-pad {
  margin-left: 24px;
  margin-right: 24px;

  @include breakpoint-md {
    margin-left: $left-padding;
  }
}

.right-pad {
  margin-right: 24px;

  @include breakpoint-md {
    margin-right: $right-padding;
  }
}

.seller-table {
  :global {
    .rc-table {
      thead {
        th {
          &:first-child {
            padding-left: $mobile-left-padding !important;

            @include breakpoint-md {
              padding-left: $left-padding !important;
            }
          }

          &:last-child {
            padding-right: $mobile-left-padding !important;

            @include breakpoint-md {
              padding-right: $right-padding !important;
            }
          }
        }
      }

      tbody {
        td {
          &:first-child {
            padding-left: $mobile-left-padding !important;

            @include breakpoint-md {
              padding-left: $left-padding !important;
            }
          }

          &:last-child {
            padding-right: $mobile-left-padding !important;

            @include breakpoint-md {
              padding-right: $right-padding / 3 !important;
            }
          }
        }
      }
    }
  }
}

.image-cropper {
  max-height: 500px !important;
}

.take-photo-modal-content {
  max-width: 750px;
}

.card-style {
  box-shadow: 0 1px 64px rgb(0 0 0 / 0.09);
  border-radius: 24px;
}

.sales-modal-content {
  background: var(--color-primary) !important;
}

.circle-progress {
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 98%;
    height: 98%;
    background: #28463d;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.circle-progress-small {
  @extend .circle-progress;

  &::before {
    width: 93%;
    height: 93%;
  }
}

.color-light-success {
  color: #37c5a4;
}

.theme-primary-border {
  border-bottom: 1px solid #fffcf919;
}

.theme-default-border {
  border-bottom: 1px solid #11262119;
}

$mobile-border-radius: 32px;

.top-rounded {
  border-top-left-radius: $mobile-border-radius;
  border-top-right-radius: $mobile-border-radius;
}

$pad: 16px;

.mobile-navbar {
  border-radius: $mobile-border-radius;
  background: #112621;
  box-shadow: 0 0 96px 0 rgb(0 0 0 / 0.7);
  backdrop-filter: blur(40px);
  z-index: 6;
  width: calc(100% - 2 * $pad);
  left: $pad;
  bottom: $pad;
}

.dashboard-header-card {
  height: 84px;
  background: rgb(17 38 33 / 0.02);
  transition: border-color 0.2s ease;

  @include breakpoint-md {
    height: 152px;
  }

  &:hover {
    border-color: var(--color-gray) !important;
  }
}

.tab-switchers {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint-md {
    white-space: initial;
    margin-right: 2rem;
  }
}

.search-container {
  top: 113px;
  z-index: 2;

  @include breakpoint-md {
    top: 180px;
  }
}

.tabs-style {
  top: 62px;
  z-index: 3;

  @include breakpoint-md {
    top: 129px;
  }
}

.search {
  padding-left: 36px;

  @include breakpoint-md {
    padding-left: 48px;
  }
}

.mobile-selected-model-navbar {
  z-index: 3;
  top: 62px;
}

.search-new-listing-input {
  &-icon {
    margin-left: 16px !important;
  }

  padding-left: 48px;

  @include breakpoint-md {
    padding-left: 48px;
  }
}

.request-sell-site-cta-btn {
  border-radius: 16px !important;
  border: 1.5px solid #02624c;
  color: #02624c;
  width: 250px;

  &:hover {
    background-color: #02624c;
    border-color: #02624c;
  }

  @include breakpoint-md {
    border: 2px solid #02624c;
    border-radius: 66px !important;
    min-width: 300px;
  }
}

.seller-site-hero-container {
  position: relative;
  right: -5%;

  @include breakpoint-md {
    width: 1340px;
    height: 800px;
    right: 25%;
    bottom: 10%;
  }

  span {
    overflow: visible !important;
  }
}

.text-success-hover:hover {
  color: var(--color-successLight) !important;
}

.settings-label {
  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--color-gray) !important;
  }
}

.addingPhotosLabel {
  transition: all 0.4s ease;
}

.selectionLabel:hover {
  border-color: var(--color-gray) !important;
}

.sellerPageWrapper {
  width: 100%;

  @include breakpoint-md {
    width: calc(100% - 100px);
    margin-left: auto;
    margin-right: 0;
  }
}

.multipleDropdown:hover {
  border-color: var(--color-primary) !important;
}

.checkbox:hover svg {
  opacity: 0.5 !important;
  transition: opacity 0.2s ease;
}
