/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * getbezel.com (monthly pageviews: <50K)
 *
 */

/*
 * Legal Disclaimer for Online Advertising licence
 *
 * Licence to use fonts in advertising on websites and mobile platforms such as banners, pop-ups, floating ads, video ads, HTML5 ads, eMail newsletters etc.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These fonts are licensed exclusively for:
 *
 * Static or dynamic online advertising
 * For up to 10k monthly ad impressions
 *
 */

/*
 * Legal Disclaimer for App licence
 *
 * Licence to use fonts in native, web, or hybrid apps on mobile or stationary devices, for example personal computers, smart phones, entertainment systems, game consoles, medical instruments, etc.
 *
 * Should the license download limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following App(s):
 *
 * Bezel (Total Downloads: <10k)
 *
 */

/* complete */

@font-face {
  font-family: BradfordLLWeb-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BookItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BookItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Regular;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Regular;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Light;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Light;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BlackItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BlackItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Italic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Italic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Medium;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Medium;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Bold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Bold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Black;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Black;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Black.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Book;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Book;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Book.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Regular;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Regular;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Light;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Light;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Book;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Book;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Book.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Italic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Italic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Medium;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Medium;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BlackItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BlackItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Bold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Bold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Black;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Black;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Black.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BookItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BookItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BookItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BookItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Book;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Book;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Book.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Medium;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Medium;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Italic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Italic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BlackItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLWeb-BlackItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Light;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Light;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Bold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Bold;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Black;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Black;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Black.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Regular;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLWeb-Regular;
  src: url('../../public/fonts/bradford/BradfordLLWeb-Regular.woff2') format('woff2');
  font-display: swap;
}

/* subset */

@font-face {
  font-family: BradfordLLSub-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BookItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BookItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Regular;
  src: url('../../public/fonts/bradford/BradfordLLSub-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Regular;
  src: url('../../public/fonts/bradford/BradfordLLSub-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Light;
  src: url('../../public/fonts/bradford/BradfordLLSub-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Light;
  src: url('../../public/fonts/bradford/BradfordLLSub-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BlackItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BlackItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Italic;
  src: url('../../public/fonts/bradford/BradfordLLSub-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Italic;
  src: url('../../public/fonts/bradford/BradfordLLSub-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Medium;
  src: url('../../public/fonts/bradford/BradfordLLSub-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Medium;
  src: url('../../public/fonts/bradford/BradfordLLSub-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Bold;
  src: url('../../public/fonts/bradford/BradfordLLSub-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Bold;
  src: url('../../public/fonts/bradford/BradfordLLSub-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Black;
  src: url('../../public/fonts/bradford/BradfordLLSub-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Black;
  src: url('../../public/fonts/bradford/BradfordLLSub-Black.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Book;
  src: url('../../public/fonts/bradford/BradfordLLSub-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Book;
  src: url('../../public/fonts/bradford/BradfordLLSub-Book.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Regular;
  src: url('../../public/fonts/bradford/BradfordLLSub-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Regular;
  src: url('../../public/fonts/bradford/BradfordLLSub-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Light;
  src: url('../../public/fonts/bradford/BradfordLLSub-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Light;
  src: url('../../public/fonts/bradford/BradfordLLSub-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Book;
  src: url('../../public/fonts/bradford/BradfordLLSub-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Book;
  src: url('../../public/fonts/bradford/BradfordLLSub-Book.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Italic;
  src: url('../../public/fonts/bradford/BradfordLLSub-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Italic;
  src: url('../../public/fonts/bradford/BradfordLLSub-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Medium;
  src: url('../../public/fonts/bradford/BradfordLLSub-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Medium;
  src: url('../../public/fonts/bradford/BradfordLLSub-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BlackItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BlackItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Bold;
  src: url('../../public/fonts/bradford/BradfordLLSub-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Bold;
  src: url('../../public/fonts/bradford/BradfordLLSub-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Black;
  src: url('../../public/fonts/bradford/BradfordLLSub-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Black;
  src: url('../../public/fonts/bradford/BradfordLLSub-Black.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BookItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BookItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BookItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BookItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BookItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-MediumItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Book;
  src: url('../../public/fonts/bradford/BradfordLLSub-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Book;
  src: url('../../public/fonts/bradford/BradfordLLSub-Book.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Medium;
  src: url('../../public/fonts/bradford/BradfordLLSub-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Medium;
  src: url('../../public/fonts/bradford/BradfordLLSub-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Italic;
  src: url('../../public/fonts/bradford/BradfordLLSub-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Italic;
  src: url('../../public/fonts/bradford/BradfordLLSub-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-LightItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBoldItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BlackItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-BlackItalic;
  src: url('../../public/fonts/bradford/BradfordLLSub-BlackItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-ExtraBold;
  src: url('../../public/fonts/bradford/BradfordLLSub-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Light;
  src: url('../../public/fonts/bradford/BradfordLLSub-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Light;
  src: url('../../public/fonts/bradford/BradfordLLSub-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Bold;
  src: url('../../public/fonts/bradford/BradfordLLSub-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Bold;
  src: url('../../public/fonts/bradford/BradfordLLSub-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Black;
  src: url('../../public/fonts/bradford/BradfordLLSub-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Black;
  src: url('../../public/fonts/bradford/BradfordLLSub-Black.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Regular;
  src: url('../../public/fonts/bradford/BradfordLLSub-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: BradfordLLSub-Regular;
  src: url('../../public/fonts/bradford/BradfordLLSub-Regular.woff2') format('woff2');
  font-display: swap;
}
