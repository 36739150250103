.emailInput {
  font-size: 14px;
  padding: 0.75rem;
  border-radius: 10px 0 0 10px;
  border: 0;
  color: #fffcf9;
  width: 100%;

  ::placeholder {
    color: #fffcf9 !important;
    opacity: 0.4 !important;
  }
}

.emailInput:focus {
  font-size: 14px;
  padding: 0.75rem;
  background-color: #1e2926;
  border-radius: 10px;
  border: 0;
  color: #fffcf9;
  width: 100%;
  box-shadow: none;

  ::placeholder {
    color: #fffcf9 !important;
    opacity: 0.4 !important;
  }
}

.emailInputGroup {
  border-radius: 10px;
  border: 1px solid rgb(255 252 249 / 0.4);
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.submitIcon {
  border-radius: 0 10px 10px 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.link {
  color: #fffcf9;
  opacity: 0.4;
}

.link:hover {
  color: #fffcf9;
  opacity: 1;
}
