/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * getbezel.com (monthly pageviews: <50K)
 *
 */

/*
 * Legal Disclaimer for Online Advertising licence
 *
 * Licence to use fonts in advertising on websites and mobile platforms such as banners, pop-ups, floating ads, video ads, HTML5 ads, eMail newsletters etc.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These fonts are licensed exclusively for:
 *
 * Static or dynamic online advertising
 * For up to 10k monthly ad impressions
 *
 */

/*
 * Legal Disclaimer for App licence
 *
 * Licence to use fonts in native, web, or hybrid apps on mobile or stationary devices, for example personal computers, smart phones, entertainment systems, game consoles, medical instruments, etc.
 *
 * Should the license download limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following App(s):
 *
 * Bezel (Total Downloads: <10k)
 *
 */

/* complete */

@font-face {
  font-family: RiformaLLWeb-Light;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Light;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Regular;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Regular;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Medium;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Medium;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Italic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Italic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-HeavyItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-HeavyItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Heavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Heavy.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Bold;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Bold;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Bold;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Bold;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Italic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Italic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Medium;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Medium;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Heavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Heavy.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Light;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Light;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-HeavyItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-HeavyItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Regular;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Regular;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Regular;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Regular;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Heavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Heavy.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-HeavyItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-HeavyItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Italic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Italic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLWeb-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Bold;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Bold;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Light;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Light;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Medium;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLWeb-Medium;
  src: url('../../public/fonts/riforma/RiformaLLWeb-Medium.woff2') format('woff2');
  font-display: swap;
}

/* subset */

@font-face {
  font-family: RiformaLLSub-Light;
  src: url('../../public/fonts/riforma/RiformaLLSub-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Light;
  src: url('../../public/fonts/riforma/RiformaLLSub-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Regular;
  src: url('../../public/fonts/riforma/RiformaLLSub-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Regular;
  src: url('../../public/fonts/riforma/RiformaLLSub-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Medium;
  src: url('../../public/fonts/riforma/RiformaLLSub-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Medium;
  src: url('../../public/fonts/riforma/RiformaLLSub-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Italic;
  src: url('../../public/fonts/riforma/RiformaLLSub-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Italic;
  src: url('../../public/fonts/riforma/RiformaLLSub-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-HeavyItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-HeavyItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLSub-Heavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLSub-Heavy.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Bold;
  src: url('../../public/fonts/riforma/RiformaLLSub-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Bold;
  src: url('../../public/fonts/riforma/RiformaLLSub-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Bold;
  src: url('../../public/fonts/riforma/RiformaLLSub-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Bold;
  src: url('../../public/fonts/riforma/RiformaLLSub-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Italic;
  src: url('../../public/fonts/riforma/RiformaLLSub-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Italic;
  src: url('../../public/fonts/riforma/RiformaLLSub-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Medium;
  src: url('../../public/fonts/riforma/RiformaLLSub-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Medium;
  src: url('../../public/fonts/riforma/RiformaLLSub-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLSub-Heavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLSub-Heavy.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Light;
  src: url('../../public/fonts/riforma/RiformaLLSub-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Light;
  src: url('../../public/fonts/riforma/RiformaLLSub-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-HeavyItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-HeavyItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Regular;
  src: url('../../public/fonts/riforma/RiformaLLSub-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Regular;
  src: url('../../public/fonts/riforma/RiformaLLSub-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Regular;
  src: url('../../public/fonts/riforma/RiformaLLSub-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Regular;
  src: url('../../public/fonts/riforma/RiformaLLSub-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLSub-Heavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Heavy;
  src: url('../../public/fonts/riforma/RiformaLLSub-Heavy.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-HeavyItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-HeavyItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-HeavyItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-LightItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-LightItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-LightItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Italic;
  src: url('../../public/fonts/riforma/RiformaLLSub-Italic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Italic;
  src: url('../../public/fonts/riforma/RiformaLLSub-Italic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-BoldItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-BoldItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-BoldItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-MediumItalic.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-MediumItalic;
  src: url('../../public/fonts/riforma/RiformaLLSub-MediumItalic.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Bold;
  src: url('../../public/fonts/riforma/RiformaLLSub-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Bold;
  src: url('../../public/fonts/riforma/RiformaLLSub-Bold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Light;
  src: url('../../public/fonts/riforma/RiformaLLSub-Light.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Light;
  src: url('../../public/fonts/riforma/RiformaLLSub-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Medium;
  src: url('../../public/fonts/riforma/RiformaLLSub-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: RiformaLLSub-Medium;
  src: url('../../public/fonts/riforma/RiformaLLSub-Medium.woff2') format('woff2');
  font-display: swap;
}
