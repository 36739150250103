.bottomSheet {
  box-shadow: 0 -2px 24px 0 rgb(0 0 0 / 0.1);
  padding: 25px 20px 30px;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--color-light);
  animation: bottom-sheet-open 0.3s ease-out forwards;
}

@keyframes bottom-sheet-open {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.bottomSheetClosing {
  animation: bottom-sheet-close 0.3s ease-out forwards;
}

@keyframes bottom-sheet-close {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
