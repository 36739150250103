@import 'mixins';

@mixin bootstrap-typography($prefix: null) {
  .fs-#{$prefix}8px {
    font-size: 8px;
  }

  .fs-#{$prefix}10px {
    font-size: 10px;
  }

  .fs-#{$prefix}12px {
    font-size: 12px;
  }

  .fs-#{$prefix}14px {
    font-size: 14px;
  }

  .fs-#{$prefix}16px {
    font-size: 16px;
  }

  .fs-#{$prefix}18px {
    font-size: 18px;
  }

  .fs-#{$prefix}20px {
    font-size: 20px;
  }

  .fs-#{$prefix}22px {
    font-size: 22px;
  }

  .fs-#{$prefix}24px {
    font-size: 24px;
  }

  .fs-#{$prefix}26px {
    font-size: 26px;
  }

  .fs-#{$prefix}28px {
    font-size: 28px;
  }

  .fs-#{$prefix}32px {
    font-size: 32px;
  }

  .fs-#{$prefix}34px {
    font-size: 34px;
  }

  .fs-#{$prefix}36px {
    font-size: 36px;
  }

  .fs-#{$prefix}40px {
    font-size: 40px;
  }

  .fs-#{$prefix}44px {
    font-size: 44px;
  }

  .fs-#{$prefix}48px {
    font-size: 48px;
  }

  .fs-#{$prefix}56px {
    font-size: 56px;
  }

  .fs-#{$prefix}64px {
    font-size: 64px;
  }

  .fs-#{$prefix}72px {
    font-size: 72px;
  }

  .fs-#{$prefix}88px {
    font-size: 88px;
  }

  .line-height-#{$prefix}12px {
    line-height: 12px;
  }

  .line-height-#{$prefix}14px {
    line-height: 14px;
  }

  .line-height-#{$prefix}16px {
    line-height: 16px;
  }

  .line-height-#{$prefix}18px {
    line-height: 18px;
  }

  .line-height-#{$prefix}20px {
    line-height: 20px;
  }

  .line-height-#{$prefix}22px {
    line-height: 22px;
  }

  .line-height-#{$prefix}24px {
    line-height: 24px;
  }

  .line-height-#{$prefix}26px {
    line-height: 26px;
  }

  .line-height-#{$prefix}28px {
    line-height: 28px;
  }

  .line-height-#{$prefix}32px {
    line-height: 32px;
  }

  .line-height-#{$prefix}36px {
    line-height: 36px;
  }

  .line-height-#{$prefix}40px {
    line-height: 40px;
  }

  .line-height-#{$prefix}42px {
    line-height: 42px;
  }

  .line-height-#{$prefix}44px {
    line-height: 44px;
  }

  .line-height-#{$prefix}48px {
    line-height: 48px;
  }

  .line-height-#{$prefix}56px {
    line-height: 56px;
  }

  .line-height-#{$prefix}64px {
    line-height: 64px;
  }

  .line-height-#{$prefix}80px {
    line-height: 80px;
  }

  .line-height-#{$prefix}88px {
    line-height: 88px;
  }

  .line-height-#{$prefix}96px {
    line-height: 96px;
  }

  .line-height-#{$prefix}104px {
    line-height: 104px;
  }
}

@include bootstrap-typography;

@include breakpoint-xs {
  @include bootstrap-typography(xs-);
}

@include breakpoint-sm {
  @include bootstrap-typography(sm-);
}

@include breakpoint-md {
  @include bootstrap-typography(md-);
}

@include breakpoint-lg {
  @include bootstrap-typography(lg-);
}

@include breakpoint-xlg {
  @include bootstrap-typography(xlg-);
}
