.text-light {
  --bs-text-opacity: 1;

  color: rgb(var(--bs-light-rgb)) !important;
}

.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.shortenLineHeight {
  line-height: 16px;
}

.title {
  @extend .truncate;
  @extend .shortenLineHeight;
}

.subtitle {
  @extend .truncate;
  @extend .shortenLineHeight;
}

.theme-primary {
  background-image: linear-gradient(180deg, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 0.29) 85.45%);
  border: 1px solid #2c3f3f;

  @extend .text-light;

  :global(.text-primary) {
    @extend .text-light;
  }

  :global(.text-success) {
    @extend .text-light;
  }

  :global(.bg-lightSuccess) {
    background-color: #647373 !important;
  }

  :global(.bg-lightPrimaryBackground) {
    background-color: #647373 !important;
  }

  a {
    &:hover {
      color: inherit;
    }
  }
}

.theme-adjustable {
  border: 1px solid rgb(255 255 255 / 0.2);
  background-image: linear-gradient(180deg, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 0.29) 85.45%);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */

  .title {
    opacity: 0.6;
  }

  :global(.text-primary) {
    @extend .text-light;
  }

  :global(.text-lightPrimaryText) {
    @extend .text-light;
  }

  :global(.text-success) {
    @extend .text-light;
  }

  :global(.bg-lightSuccess) {
    background: rgb(255 255 255 / 0.07) !important;
  }

  :global(.bg-lightPrimaryBackground) {
    background: rgb(255 255 255 / 0.07) !important;
  }

  a:hover {
    color: inherit;
  }
}

.inquire {
  &:hover {
    opacity: 1;
    background-color: rgb(26 58 50 / 0.05) !important;
  }
}
